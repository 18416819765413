<template>
  <div class="page-acceptance">
    <div v-if="place.length>0" class="place-box flex items-center">
      <template v-for="(item, index) in place">
        <div :key="index" class="place">
          {{ item }}
        </div>
        <van-icon :key="index+'icon'" name="arrow" />
      </template>
    </div>
    <div class="flex items-center mt-0.5 px-2.5 py-1 bg-white">
      <div class="font-bold flex-1">{{ projectName }}</div>
      <div class="flex flex-col  items-center" @click="reset">
        <div class="icon-replay" />
        <span class="text-xs font-bold">返回</span>
      </div>
    </div>
    <!-- tab -->
    <div v-if="$route.query.page==1" class="flex text-sm bg-white mt-0.5">
      <template v-for="(item, index) in tab">
        <div
          :key="index"
          v-if="item.isShow"
          class=" flex justify-center"
          style="width:50%"
          :class="{ 'tab-active': tab_active == item.type }"
          @click="tabClick(item)"
        >
          <div class="tab-text">{{ item.name }}</div>
        </div>
      </template>
    </div>

    <!-- 交付信息 -->
    <van-collapse v-if="JSON.stringify(building)!='{}'&&tab_active==2" v-model="export_building" class="mt-2 ">
      <van-collapse-item name="1">
        <template #title>
          <div class="content-title text-sm">交付信息</div>
        </template>
        <div class=" text-sm bg-white px-2.5 pb-5" style="overflow: hidden;">
          <template v-for="(item, key ,index) in building">
            <div class="mt-3 text-sm text-gray-600" :key="index"> {{key==1?'毛坯交付':'精装交付'}}：{{item}}</div>
          </template>
        </div>
        
      </van-collapse-item>
    </van-collapse>

    <!-- 人员信息 -->
    <van-collapse v-model="export_prople" class="mt-2 ">
      <van-collapse-item name="1">
        <template #title>
          <div class="content-title text-sm flex" style="justify-content: space-between;">
            人员信息
            <van-button type="default" @click.stop="$router.push({path:'/regulate/project/people',query:{id:form.projectId,acceptanceType:tab_active}})" style="margin-right:10px;width:50px" plain size="mini">编辑</van-button>
          </div>
        </template>
        <div class=" text-sm bg-white  pb-5" style="overflow: hidden;">
          <template v-for="(item, key ,index) in personnel">
              
                <div class="text-base font-medium text-gray-900 mt-4 px-2.5" :key="index">{{key}}</div>
                <div v-for="(im, k, ix) in item" class="px-2.5" :key="ix+'-'+ index">
                  <div class="mt-3 text-sm text-gray-600" v-if="im">{{k}}：{{im}}</div>
                </div>
          </template>
        <!-- <div class="px-2.5 divide-y divide-opacity-5">
          <div class="flex items-center text-black py-2.5 text-sm" v-for="(item, key, index) in personnel" :key="index">
            <div class="">{{key}}：</div>
            <div class="">{{item}}</div>
          </div>
        </div> -->
        </div>
        
      </van-collapse-item>
    </van-collapse>


    <!-- 集团人员 -->
    <!-- <van-collapse v-model="export_group" class="mt-2 ">
      <van-collapse-item name="1">
        <template #title>
          <div class="content-title text-sm">集团人员</div>
        </template>
        <div style="width: 100vw">
          <el-table
            :data="group.data"
            border
            size="mini"
            style="width: 100%">
            <el-table-column
              v-for="(item, index) in group.menu"
              :width="index==0&&'80'"
              :key="index"
              :prop="item.prop"
              :label="item.name">
            </el-table-column>
          </el-table>
        </div>
      </van-collapse-item>
    </van-collapse> -->

    
    <!-- 供方信息 -->
    <van-collapse v-model="export_supplier" class="mt-2 ">
      <van-collapse-item name="1">
        <template #title>
          <div class="content-title text-sm flex" style="justify-content: space-between;">
            供方信息
            <van-button type="default" @click.stop="$router.push({path:'/regulate/project/supplier',query:{id:form.projectId,acceptanceType:tab_active}})" style="margin-right:10px;width:50px" plain size="mini">编辑</van-button>
          </div>
        </template>
        <template>
          <div class="text-sm bg-white  pb-5">
            <div class="flex">
              <div
                v-for="(item, key ,index) in supply"
                :key="index"
                class="flex-1 flex justify-center supplier-tab"
                :class="{ 'tab-active': supplier_tab_active.type == index }"
                @click="supplierTabClick(index)"
                v-show="tabs.indexOf(key)!=-1"
              >
                <div class="flex-1 text-center">
                  <div class="tab-text relative inline-block">{{ key }}</div>
                </div>
              </div>

            </div>
            <template v-for="(item, key ,index) in supply">
              <template v-if="index==supplier_tab_active.type">
                <div v-for="(im, k, ix) in item" class="mt-6 px-2.5" :key="ix+'-'+ index">
                  <div class="text-base font-medium text-gray-900">{{k}}</div>
                  <div class="mt-4 text-sm text-gray-600" v-for="(v, j, i) in im" :key="i">{{j}}：{{v}}</div>
                </div>
              </template>
            </template>
            
          </div>
        </template>
      </van-collapse-item>
    </van-collapse>

    <!-- 选择样板房类型 -->
    <van-collapse v-show="list.length>0" v-model="export_results" class="mt-2 ">
      <van-collapse-item name="1">
        <template #title>
          <div class="content-title text-sm">验收结果</div>
        </template>
        <div class="px-2.5 bg-white divide-y divide-opacity-5">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="flex items-center py-3.5 text-black"
            @click="itemClick(item)"
          >
            <div v-if="item.state==null" class="flex-1 text-sm leading-none">{{ item.name }}</div>
            <div v-if="item.state==0" class="flex-1 text-sm leading-none">{{ item.name }}（暂存）</div>
            <div v-if="item.state==1" class="flex-1 text-sm leading-none">{{ item.name }}（提交）</div>
            <div v-if="item.state==2" class="flex-1 text-sm leading-none">{{ item.name }}<font style="color:red">（发布）</font></div>

            <div class="pr-1" v-if="item.score!=null">
                    <span class="score-text">{{parseNum(item.score)}}</span>
                    <span class="text-xs ml-1">分</span>
            </div>
            <van-icon name="arrow" size="14" color="#aaa" />
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import { Icon, Picker, Popup, DatetimePicker, Collapse, CollapseItem, Button, Notify } from 'vant'
import score from 'components/score'
import scoreEdit from 'components/score_edit'
import { specialTables, housesList } from '../../../api'
export default {
  name:'',
  components:{
    [Icon.name]:Icon,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [DatetimePicker.name]:DatetimePicker,
    [Collapse.name]:Collapse,
    [CollapseItem.name]:CollapseItem,
    [Button.name]:Button,
    [Notify.name]:Notify,
    // vanImg: Image,
    comScore:score,
    scoreEdit
  },
  data() {
    return {
      list:[],
      form:{},
      screen_date:'',
      tab:{
        1:{
          name: "展示区",
          type: 1,
          isShow: false
      },
      2:{
          name: "大盘区",
          type: 2,
          isShow: false
      }},
      tab_active:1,
      supplier_tab_active:{
        type:0
      },
      place:[],
      projectName:'',
      export_results:[],
      export_prople:[],
      export_supplier:[],
      export_group: [],
      export_building: [],
      building: {},
      personnel: {},
      supply: {},
      group: {
        data: [],
        menu: []
      },
      authority: 'false',
      tabs: ''
    }
  },
  watch:{
    '$route.query'(val) {
      this.list = []
      this.formData()
      this.request()
    }
  },
  created() {
    this.$notify.clear()
    this.authority = localStorage.getItem('authority')
    var query = this.$route.query
    this.screen_date = query.screen_date
    this.projectName = localStorage.getItem('projectName')
    var acceptanceType = query.acceptanceType?query.acceptanceType.split(','):[1,2]
    for (var item of acceptanceType) {
      if (acceptanceType.length==1) {
        this.tab_active = item
      }
      this.tab[item].isShow = true
    }
    this.formData()
    this.request()
  },
  methods:{
    // 重置
    reset() {
      // var step = -this.$route.query.page

      this.$router.go(-1)
    },
    tabClick(item) {
      this.tab_active = item.type
      this.list = []
      this.supplier_tab_active={
        type:0
      }
      this.specialTables()
    },
    formData() {
      this.form = JSON.parse(localStorage.getItem('form'))
    },
    itemClick(item) {
      
      var query = this.$route.query
      if (query.page == 1) {
        //判断供方信息是否填写
        for (var key in this.supply) {
          if (item.name.indexOf(key)>-1) {
              for (var k in this.supply[key]) {
                for (v in this.supply[key][k]) {
                  if (this.supply[key][k][v]=='') {
                    return this.$toast('请完善供方信息“'+item.name+'信息”')
                  }
                }
              }
          }
        }
        this.form.acceptanceType = this.tab_active
        this.form.specialId = item.id
        this.form.housesId = null
        localStorage.setItem('form', JSON.stringify(this.form))
        var formInfo = JSON.parse(localStorage.getItem('formInfo'))
        for (var k in this.tab) {
          var v = this.tab[k]
          if (v.type == this.tab_active) {
            formInfo.arr += ',' + v.name
          }
        }
        if (item.enableHouses == 1) {
          formInfo.arr += ',' + item.specialName
          formInfo.page = 2
          this.$router.push({
            path:'/acceptance/list',
            query:formInfo
          })
        } else {
          // 样板房没有启动
          formInfo.page = 3
          
          if (item.state==2) {
            formInfo.readOnly = 1
            this.$router.push({ path:'/acceptanceResults/list/details', query:formInfo })
          } else {
            this.$router.push({ path:'/acceptance/list/details', query:formInfo })
          }
        }
      } else {
        this.form.housesId = item.id
        query.page = 3
        localStorage.setItem('form', JSON.stringify(this.form))
        if (item.state==2) {
            query.readOnly = 1
            this.$router.push({ path:'/acceptanceResults/list/details', query:query })
          } else {
            this.$router.replace({ path:'/acceptance/list/details', query:query })
        }
        // this.$router.replace({ path:'/acceptance/list/details', query:query })
      }
    },
    specialTables() {
      this.$toast.loading({
        duration:0,
        forbidClick:true
      })
      specialTables({
        type:this.tab_active,
        projectId: this.form.projectId,
        queryType:0
      }).then((res) => {
        this.tabs = String(res.data.data.tabs)
        var data = res.data.data.items
        this.building = res.data.data.building?JSON.parse(res.data.data.building):{}
        for (var item of data) {
          item.name = item.specialName
          //data长度大于1时候展开
          this.export_results=['1']
        }
        this.list = data
        for (var item of res.data.data.projectInfos) {
          if (item.type==0) {
            this.personnel = JSON.parse(item.projectData)
          }  else if (item.type==1) {
              this.supply = JSON.parse(item.projectData)
          } else {
            this.group = JSON.parse(item.projectData)
          }
        }
      }).finally(() => {
        this.$toast.clear()
      })
    },
    request() {
      var page = this.$route.query.page
      if (page == 1) {
        this.place = JSON.parse(localStorage.getItem('formInfo')).arr.split(',')
        this.specialTables()
      } else if (page == 2) {
        housesList({
          projectId:this.form.projectId,
          queryType:0
        }).then((res) => {
          var data = res.data.data

          this.list = data
        })
      }
    },
    supplierTabClick(index) {
      this.supplier_tab_active.type = index
    },
    parseNum(value){
        return parseFloat(value);
    }
  }
}
</script>
<style lang="scss" scoped>
.page-acceptance {
    min-height: 100vh;
    background-color: #f6f6f6;
    box-sizing: border-box;
    overflow: hidden;
}

.tab-text {
    position: relative;
    padding: 14px 30px;
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 2px;
    }
}

.tab-active {
    .tab-text {
        color: #6396fe;
        &:before {
            background-color: #6396fe;
        }
    }
}
.screen-wrap {
    display: flex;
    align-items: center;
    min-width: calc((100% - 24px) / 3);
    max-width: calc((100% - 24px) / 3);
    padding: 6px 10px;
    border: 1px solid rgba(#000, 0.2);
    color: #666;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;
    box-sizing: border-box;
    & + .screen-wrap {
        margin-left: 12px;
    }
}
.empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 64px 20px;
    &-img {
        width: 214px;
    }
}

.huses-item {
    box-sizing: border-box;
    padding: 8px;
    background-color: #fff;
    border-radius: 5px;
    width: calc((100% - 8px) / 2);
    margin-bottom: 8px;
    margin-left: 8px;
    &:nth-child(2n + 1) {
        margin-left: 0;
    }
}
.houses-img-wrap {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.houses-img {
    object-fit: contain;
    
}
.icon-replay {
    width: 14px;
    height: 14px;
    background-image: url("../../../assets/acceptance/replay.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.place-box{
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    padding: 0 10px;
    margin: 1px 0;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.place-box .van-icon-arrow {
    line-height: 40px;
    margin: 0 7px;
}
.place-box .van-icon-arrow:nth-last-child(1){
    display: none;
}
/deep/ .van-collapse-item__title{
  padding:10px;
  &:after{
    width:100%;
    left:0;
    right:0;
  }
}
/deep/ .van-collapse-item__content{
  padding:0;
}
.supplier-tab{
  .tab-text{
    padding:14px 10px;
  }
}
</style>
